<script setup lang="ts">
import useNotificationStore from "@/stores/notifications";
import { useUserStore } from "@/stores/user";

defineProps<{ isOpen: boolean }>();
const notifier = useNotificationStore();
const userStore = useUserStore();
</script>
<template>
  <router-link
    v-if="notifier.platformAlert?.active"
    :to="userStore.landingPage"
    class="flex h-6 w-full items-center justify-center gap-1.5 overflow-clip px-4 py-2 text-[10px] font-semibold"
    :class="{
      'bg-orange': notifier.platformAlert?.status === 'warning',
      'bg-green-olive': notifier.platformAlert?.status === 'success',
    }"
    @click="notifier.platformAlertInfo = undefined"
  >
    <span class="text-base text-gray-900">
      <i class="fa-solid fa-triangle-exclamation"></i>
    </span>
    <p v-if="isOpen" class="whitespace-nowrap">
      {{ notifier.platformAlert?.title || "Potential CN/CPKC Embargos/Shutdown" }}
    </p>
  </router-link>
</template>
