import { HandlerResponse } from "../../types/handlerResponse";
import { BAPI_COMMANDS } from "../../types/commands";
import { fetchHandler } from "@/bapi-client/fetch";
import { customerSupportTicketFactory } from "@/bapi-client/factories/customer-support";
import {
  BAPICreateTicketParams,
  BAPIUpdateTicketParams,
  DeleteCustomerSupportTicketParams,
} from "@/bapi-client/types/customer-support";

export async function createTicket(
  form: BAPICreateTicketParams,
): HandlerResponse[typeof BAPI_COMMANDS.CUSTOMER_SUPPORT_CREATE_TICKET] {
  const response = await fetchHandler(
    BAPI_COMMANDS.CUSTOMER_SUPPORT_CREATE_TICKET,
    "POST",
    `/support/${form.customer_id}`,
    {
      body: form,
    },
  );
  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;
  return {
    success: true,
    data: customerSupportTicketFactory(parsed.data),
  };
}

export async function deleteTicket({
  customerId,
  ticketId,
}: DeleteCustomerSupportTicketParams): HandlerResponse[typeof BAPI_COMMANDS.CUSTOMER_SUPPORT_DELETE_TICKET] {
  const response = await fetchHandler(
    BAPI_COMMANDS.CUSTOMER_SUPPORT_DELETE_TICKET,
    "DELETE",
    `/support/${customerId}${ticketId}`,
  );
  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return { success: response.success, data: customerSupportTicketFactory(parsed.data) };
}

export async function updateTicket(
  form: BAPIUpdateTicketParams,
): HandlerResponse[typeof BAPI_COMMANDS.CUSTOMER_SUPPORT_UPDATE_TICKET] {
  const body = form;
  delete (body as { customer_id?: string })?.customer_id;
  delete (body as { ticket_id?: string })?.ticket_id;

  const response = await fetchHandler(
    BAPI_COMMANDS.CUSTOMER_SUPPORT_UPDATE_TICKET,
    "PATCH",
    `/support/${form.customer_id}${form.ticket_id}`,
    {
      body,
    },
  );
  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return { success: response.success, data: customerSupportTicketFactory(parsed.data) };
}
