import {
  BAPICustomerSupportTicketResponse,
  HandlerCustomerSupportTicketResponse,
} from "@/bapi-client/types/customer-support";

export function customerSupportTicketFactory(
  data: BAPICustomerSupportTicketResponse,
): HandlerCustomerSupportTicketResponse {
  return {
    id: data.id,
    customerId: data.customer_id,
    idPrefix: data.id_prefix,
    name: data.name,
    state: data.state,
    createdTs: data.created_ts,
    status: data.status,
    version: data.version,
    updatedTsMax: data.updated_ts_max ?? null,
    updatedTsMin: data.updated_ts_min ?? null,
    createdByUserId: data.created_by_user_id,
    lastEditedByUserId: data.last_edited_by_user_id,
    qualifiedId: data.qualified_id,
    closedTsMax: data.closed_ts_max ?? null,
    closedTsMin: data.closed_ts_min ?? null,
    assetIds: data.asset_ids ?? null,
    reportingGroups: data.reporting_groups,
    priority: data.priority,
    loadedEmpty: data.loaded_empty,
    railroads: data.railroads,
    assignees: data.assignees,
    followers: data.followers,
    tags: data.tags,
    description: data.description,
    sortBy: data.sort_by ?? null,
  };
}
